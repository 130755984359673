import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_CHECK, AUTH_ERROR, AUTH_GET_PERMISSIONS } from 'react-admin';
const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:8000';

const authProvider = (type, params) => {
    if (type === AUTH_LOGIN) {
        const { username, password } = params;
        const request = new Request(apiUrl + '/auth/login', {
            method: 'POST',
            body: JSON.stringify({ username, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then(({ id, access_token, permission }) => {
                localStorage.setItem('access_token', access_token);
                localStorage.setItem('permission', permission);
                localStorage.setItem('user_id', id);
            });
    }
    if (type === AUTH_LOGOUT) {
        localStorage.removeItem('access_token');
        return Promise.resolve();
    }
    if (type === AUTH_ERROR) {
        const { status } = params;

        if (status === 401 || status === 403) {
            localStorage.removeItem('access_token');
            return Promise.reject();
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    }
    if (type === AUTH_CHECK) {
        return localStorage.getItem('access_token')
            ? Promise.resolve()
            : Promise.reject({ redirectTo: '/login' });
    }
    if (type === AUTH_GET_PERMISSIONS) {
        const permission = localStorage.getItem('permission');
        return permission ? Promise.resolve(permission) : Promise.reject();
    }
    return Promise.reject('Unknown method');
};

export default authProvider;